import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize, map } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { BatchInsertable } from '../batch-insertable';
import { IPackagegoodsBookingCalendarDto } from '../packagegoods/packagegoods.model';
import { IHalPageResponse, IPage } from '../page.model';
import { IMrhst } from './mrhst.model';

@Injectable({
  providedIn: 'root',
})
export class MrhstApi
  extends PageRepositoryService<IMrhst>
  implements BatchInsertable
{
  override baseUri = 'api/mrhst';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  findPageModal(params: any = {}): Observable<IPage<IMrhst>> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IHalPageResponse>(`${this.apiServerUrl}/${this.baseUri}/modal`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        map((res) => this.parsePage(res)),
        this.handleError(),
      );
  }

  getPageModal(query = this.recentSearchQuery): void {
    this.isListLoading = true;

    this.findPageModal(query).subscribe({
      next: (res) => {
        // 성공했다면 최근 조건으로 set
        this.recentSearchQuery = query;

        if (!this['page']) {
          this['page'] = {} as IPage;
        }

        this['page']!.content = res.content;
        this['page']!.page = res.page;

        this.listSubject.next(this['page']);
      },
      error: (error) => {
        this.isListLoading = false;
        this.initList();
        this.listSubject.error(error);
      },
    });
  }

  /**
   * 매장 일괄 등록
   */
  batchInsert(body: any, params: any): Observable<any> {
    this.isDetailLoading = true;
    return this.http
      .post<IMrhst>(`${this.apiServerUrl}/${this.baseUri}/list`, body, {
        params,
      })
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }

  /**
   * 재고 조회
   */
  getInventory(params: any): Observable<IPackagegoodsBookingCalendarDto[]> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http.get<IPackagegoodsBookingCalendarDto[]>(
      `${this.apiServerUrl}/${this.baseUri}/inventory`,
      { params: httpParams },
    );
  }

  /**
   * 테마이라즈 시설 목록 조회
   *
   * 고객 또는 익명 사용자는 브랜드 관계 없이 조회 가능
   */
  getTemairazuMrhstList(params: any): Observable<IPage<IMrhst>> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IHalPageResponse>(`${this.apiServerUrl}/${this.baseUri}/temairazu`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        map((response) => this.parsePage(response)),
        this.handleError(),
      );
  }

  getTemairazuMrhstListHomepage(params: any): Observable<IMrhst[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IMrhst[]>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/temairazu`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.handleError(),
      );
  }

  /**
   * 테마이라즈 시설 상세 조회
   *
   * 고객 또는 익명 사용자는 브랜드 관계 없이 조회 가능
   */
  getTemairazuMrhst(id: number): Observable<IMrhst> {
    this.isDetailLoading = true;

    return this.http
      .get<IMrhst>(`${this.apiServerUrl}/${this.baseUri}/temairazu/${id}`)
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }
}
